import { useMemo } from 'react'
import { formatNumberCompact } from '~/utils/numbers'

type Bar = {
  label: string
  value: number
}

export const HorizontalBarChart = <T extends Bar>({
  bars,
  renderLabel,
}: { bars: T[]; renderLabel?: (bar: T) => React.ReactNode }) => {
  const maxValue = useMemo(
    () =>
      bars.reduce((acc, cur) => {
        return cur.value > acc ? cur.value : acc
      }, 0),
    [bars]
  )

  return (
    <div className="space-y-2 font-medium text-sm">
      {bars.map((bar) => (
        <Bar
          key={bar.label}
          bar={bar}
          maxValue={maxValue}
          renderLabel={renderLabel}
        />
      ))}
    </div>
  )
}

const Bar = <T extends Bar>({
  bar,
  maxValue,
  renderLabel = (bar) => bar.label,
}: { bar: T; maxValue: number; renderLabel?: (bar: T) => React.ReactNode }) => {
  const width = (bar.value / maxValue) * 100

  return (
    <div className="flex items-center justify-between gap-8">
      <div className="h-6 w-full">
        <div
          className="h-full whitespace-nowrap rounded-r-md bg-accent-500/10 transition hover:bg-accent-500/20"
          style={{ width: `${width}%` }}
        >
          {renderLabel(bar)}
        </div>
      </div>
      <div className="shrink-0 text-right">
        {formatNumberCompact(bar.value)}
      </div>
    </div>
  )
}
